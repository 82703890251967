import Vue from 'vue';
Vue.directive('forbid-special-chars', {
  bind: function bind(el) {
    el.handleInput = function (event) {
      var inputValue = event.target.value;
      // 正则规则排除表情符号、特殊字符和空格
      var regRule = /[\uD800-\uDFFF\u2600-\u2B55\u23CF\u23E9-\u23F3\u231A\u231B\u23E3\u3030\u25AB\u25FE!@#$%^&*(),.?":{}|<> ]/g;
      if (regRule.test(inputValue)) {
        // 如果输入值包含特殊字符，则替换掉特殊字符
        event.target.value = inputValue.replace(regRule, '');
      }
    };

    // 监听输入事件
    el.addEventListener('input', el.handleInput);
  },
  unbind: function unbind(el) {
    // 移除监听器
    el.removeEventListener('input', el.handleInput);
  }
});
Vue.directive('only-number', {
  bind: function bind(el) {
    el.handleInput = function (event) {
      var inputValue = event.target.value;
      // 正则规则只允许输入数字
      var regRule = /[^0-9]/g;
      if (regRule.test(inputValue)) {
        // 如果输入值包含非数字字符，则替换掉非数字字符
        var newValue = inputValue.replace(regRule, '');
        event.target.value = newValue;
        // 更新输入框的值
        el.dispatchEvent(new Event('input', {
          bubbles: true
        }));
      }
    };

    // 监听输入事件
    el.addEventListener('input', el.handleInput);

    // 监听粘贴事件
    el.addEventListener('paste', function (event) {
      // 阻止默认粘贴行为
      event.preventDefault();
      // 获取粘贴板上的文本
      var clipboardData = event.clipboardData || window.clipboardData;
      var pastedText = clipboardData.getData('text');
      // 正则规则只允许数字
      var regRule = /[^0-9]/g;
      // 替换掉非数字字符
      var newValue = pastedText.replace(regRule, '');
      // 将处理后的文本粘贴到输入框中
      document.execCommand('insertText', false, newValue);
      // 更新输入框的值
      el.dispatchEvent(new Event('input', {
        bubbles: true
      }));
    });
  },
  unbind: function unbind(el) {
    // 移除监听器
    el.removeEventListener('input', el.handleInput);
  }
});
Vue.directive('only-alphanumeric', {
  bind: function bind(el) {
    el.handleInput = function (event) {
      var inputValue = event.target.value;
      // 正则规则只允许输入数字和字母
      var regRule = /[^a-zA-Z0-9]/g;
      if (regRule.test(inputValue)) {
        // 如果输入值包含非数字和非字母字符，则替换掉这些字符
        event.target.value = inputValue.replace(regRule, '');
      }
    };
    // 监听输入事件
    el.addEventListener('input', el.handleInput);
  },
  unbind: function unbind(el) {
    // 移除监听器
    el.removeEventListener('input', el.handleInput);
  }
});
Vue.directive('only-numeric-and-dot', {
  bind: function bind(el) {
    el.handleInput = function (event) {
      var inputValue = event.target.value;
      // 正则规则只允许输入数字和点，且不允许输入空格
      var regRule = /[^0-9.]/g;
      if (regRule.test(inputValue)) {
        // 如果输入值包含非数字和非点字符，则替换掉这些字符
        event.target.value = inputValue.replace(regRule, '');
        // 手动触发 input 事件更新 v-model 绑定的值
        event.target.dispatchEvent(new Event('input'));
      }
    };
    // 监听输入事件
    el.addEventListener('input', el.handleInput);

    // 监听 keypress 事件，防止空格输入
    el.handleKeyPress = function (event) {
      var char = String.fromCharCode(event.charCode);
      var regRule = /[^0-9.]/;
      if (regRule.test(char)) {
        event.preventDefault();
      }
    };
    el.addEventListener('keypress', el.handleKeyPress);
  },
  unbind: function unbind(el) {
    // 移除监听器
    el.removeEventListener('input', el.handleInput);
    el.removeEventListener('keypress', el.handleKeyPress);
  },
  update: function update(el) {
    // 初始化时和更新时都需要执行一次，确保初始值和动态变化值符合规则
    var inputValue = el.value || ''; // Ensure inputValue is a string
    var regRule = /[^0-9.]/g;
    if (regRule.test(inputValue)) {
      el.value = inputValue.replace(regRule, '');
      el.dispatchEvent(new Event('input'));
    }
  }
});