import modalAttr from '@/libs/modal-attr';
import modalParserFrom from '@/libs/modal-parserFrom';
import modalSure from '@/libs/modal-sure';
import modalPrompt from '@/libs/modal-prompt';
import timeOptions from '@/libs/timeOptions';
import * as constants from '@/utils/constants.js';
import * as selfUtil from '@/utils/ZBKJIutil.js';
import schema from 'async-validator';
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree } from '@/utils/parsing';
export default {
  install: function install(Vue) {
    Vue.prototype.$modalSure = modalSure;
    Vue.prototype.$modalAttr = modalAttr;
    Vue.prototype.$modalParserFrom = modalParserFrom;
    Vue.prototype.$timeOptions = timeOptions;
    Vue.prototype.$constants = constants;
    Vue.prototype.$selfUtil = selfUtil;
    Vue.prototype.handleTree = handleTree;
    Vue.prototype.parseTime = parseTime;
    Vue.prototype.resetForm = resetForm;
    Vue.prototype.$modalPrompt = modalPrompt;
    Vue.prototype.$validator = function (rule) {
      return new schema(rule);
    };
  }
};