/** When your routing table is too long, you can split it into small modules **/

import Layout from '@/layout';
var maintainRouter = {
  path: '/maintain',
  component: Layout,
  redirect: '/maintain/devconfiguration/configCategory',
  name: 'maintain',
  meta: {
    title: '维护',
    icon: 'clipboard'
  },
  children: [{
    path: 'user',
    name: 'user',
    component: function component() {
      return import('@/views/maintain/user');
    },
    meta: {
      title: '个人中心',
      icon: 'clipboard'
    },
    hidden: true
  }, {
    path: 'picture',
    name: 'picture',
    component: function component() {
      return import('@/views/maintain/picture');
    },
    meta: {
      title: '素材管理',
      icon: 'clipboard'
    },
    hidden: false
  }, {
    path: 'logistics',
    name: 'Logistics',
    alwaysShow: true,
    redirect: '/logistics/cityList',
    component: function component() {
      return import('@/views/maintain');
    },
    meta: {
      title: '物流管理',
      icon: 'clipboard',
      roles: ['admin']
    },
    children: [{
      path: 'companyList',
      component: function component() {
        return import('@/views/maintain/logistics/companyList');
      },
      name: 'companyList',
      meta: {
        title: '物流公司',
        icon: ''
      }
    }]
  }, {
    path: 'sensitiveLog',
    name: 'sensitiveLog',
    component: function component() {
      return import('@/views/maintain/sensitiveList');
    },
    meta: {
      title: '敏感操作日志',
      icon: 'clipboard'
    },
    hidden: false
  }]
};
export default maintainRouter;