import Layout from '@/layout';
var userRouter = {
  path: '/user',
  component: Layout,
  redirect: '/user/index',
  name: 'User',
  meta: {
    title: '用户',
    icon: 'clipboard'
  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/user/list/index');
    },
    name: 'UserIndex',
    meta: {
      title: '用户管理',
      icon: ''
    }
  }]
};
export default userRouter;