//商户过滤器

/**
 * 商户创建类型
 */
export function merCreateTypeFilter(status) {
  var statusMap = {
    admin: '管理员创建',
    apply: '商户入驻申请'
  };
  return statusMap[status];
}

/**
 * 商户类别
 */
export function selfTypeFilter(status) {
  var statusMap = {
    true: '自营',
    false: '非自营'
  };
  return statusMap[status];
}