//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ThemePicker from '@/components/ThemePicker';
export default {
  components: {
    ThemePicker: ThemePicker
  },
  data: function data() {
    return {
      sideTheme: this.$store.state.settings.sideTheme,
      routers: this.$store.state.permission.routes
    };
  },
  computed: {
    theme: {
      get: function get() {
        return this.$store.state.settings.theme;
      }
    },
    fixedHeader: {
      get: function get() {
        return this.$store.state.settings.fixedHeader;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'fixedHeader',
          value: val
        });
      }
    },
    topNav: {
      get: function get() {
        //回调函数 当需要读取当前属性值是执行，根据相关数据计算并返回当前属性的值
        return this.$store.state.settings.topNav;
      },
      set: function set(val) {
        var _this = this;
        //监视当前属性值的变化，当属性值发生变化时执行，更新相关的属性数据
        //val就是topNav的最新属性值
        this.$store.dispatch('settings/changeSetting', {
          key: 'topNav',
          value: val
        });
        if (val) {
          var key = this.$route.path.split('/')[1];
          //通过截取当前路由的第一级目录跟顶部一级菜单选中项的val值做匹配
          key = '/' + key;
          this.routers.map(function (item) {
            if (key == item.url && item.child) {
              //如果匹配，就给侧边导航赋值为选中项的子级数组
              _this.$store.commit('permission/SET_SIDEBAR_ROUTERS', item.child);
            } else if (key == item.url && !item.child) {
              //如果遍历以后val值等于item的url，但是有没有子级，就把它子级赋值给侧边导航的数组，这里针对dashboard控制台
              _this.$store.commit('permission/SET_SIDEBAR_ROUTERS', [item]);
            }
          });
        }
        if (!val) {
          //关闭的时候侧边导航的取值还是取默认的routes数组
          this.$store.commit('permission/SET_SIDEBAR_ROUTERS', this.$store.state.permission.routes);
        }
      }
    },
    navIcon: {
      get: function get() {
        return this.$store.state.settings.navIcon;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          //dispatch：含有异步操作，例如向后台提交数据，写法： this.$product.dispatch('action方法名',值)
          //commit：同步操作，写法：this.$product.commit('mutations方法名',值)
          key: 'navIcon',
          value: val
        });
      }
    },
    tagsView: {
      get: function get() {
        return this.$store.state.settings.tagsView;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'tagsView',
          value: val
        });
      }
    },
    sidebarLogo: {
      get: function get() {
        return this.$store.state.settings.sidebarLogo;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'sidebarLogo',
          value: val
        });
      }
    }
  },
  methods: {
    themeChange: function themeChange(val) {
      this.$store.dispatch('settings/changeSetting', {
        key: 'theme',
        value: val
      });
      this.theme = val;
    },
    handleTheme: function handleTheme(val) {
      this.$store.dispatch('settings/changeSetting', {
        key: 'sideTheme',
        value: val
      });
      this.sideTheme = val;
    },
    saveSetting: function saveSetting() {
      this.$modal.loading('正在保存到本地，请稍候...');
      //将设置写入缓存
      this.$cache.local.setJSON('layout-setting', {
        topNav: this.topNav,
        tagsView: this.tagsView,
        fixedHeader: this.fixedHeader,
        sidebarLogo: this.sidebarLogo,
        dynamicTitle: this.dynamicTitle,
        sideTheme: this.sideTheme,
        theme: this.theme,
        navIcon: this.navIcon
      });
      setTimeout(this.$modal.closeLoading(), 1000);
    },
    resetSetting: function resetSetting() {
      this.$modal.loading('正在清除设置缓存并刷新，请稍候...');
      this.$cache.local.remove('layout-setting');
      setTimeout('window.location.reload()', 1000);
    }
  }
};