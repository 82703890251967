import request from '@/utils/request';
export function login(data) {
  return request({
    url: '/admin/merchant/login',
    method: 'post',
    data: data
  });
}
export function getInfo(token) {
  return request({
    url: '/admin/merchant/getAdminInfoByToken',
    method: 'get',
    params: {
      token: token
    }
  });
}
export function getAdminInfoByUrl(token) {
  return request({
    url: '/admin/merchant/getAdminInfoByUrl',
    method: 'get',
    params: {
      token: token
    }
  });
}
export function logout() {
  return request({
    url: '/admin/merchant/logout',
    method: 'get'
  });
}

/**
 * 会员管理 列表
 * @param pram
 */
export function userListApi(params) {
  return request({
    url: "/admin/merchant/user/list",
    method: 'get',
    params: params
  });
}

/**
 * 会员管理 绑定
 * @param pram
 */
export function userBindUser(params) {
  return request({
    url: "/admin/merchant/user/bind/user",
    method: 'get',
    params: params
  });
}

/**
 * 会员管理 解绑
 * @param pram
 */
export function userUnBind(params) {
  return request({
    url: "/admin/merchant/user/un/bind",
    method: 'get',
    params: params
  });
}

/**
 *获取登录页图片
 */
export function getLoginPicApi() {
  return request({
    url: "/admin/merchant/getLoginPic",
    method: 'get'
  });
}

/**
 * @description 验证码
 */
export function captchaApi() {
  return request({
    url: "/publicly/validate/code/get",
    method: 'get'
  });
}

/**
 * @description 用户详情列表
 */
export function userDetailApi(id) {
  return request({
    url: "/admin/merchant/user/detail/".concat(id),
    method: 'get'
  });
}

/**
 * 商户登录--获取
 * @param data
 */
export function platformUrlLogin(merId, token) {
  return request({
    url: "/admin/platform/url/login/".concat(merId, "/").concat(token),
    method: 'get'
  });
}
// http://127.0.0.1:8080/api/admin/platform/url/login/3/platform7e137477d74c4c13939763e77fe6033f