import Layout from '@/layout';
var financeRouter = {
  path: "/finance",
  name: 'finance',
  meta: {
    icon: '',
    title: '财务'
  },
  alwaysShow: true,
  component: Layout,
  children: [{
    path: 'statement',
    name: 'FinanceStatement',
    meta: {
      title: '账单管理',
      noCache: true
    },
    component: function component() {
      return import('@/views/finance/statement/index');
    }
  }, {
    path: 'capitalFlow',
    name: 'FinanceCapitalFlow',
    meta: {
      title: '资金流水',
      noCache: true
    },
    component: function component() {
      return import('@/views/finance/capitalFlow/index');
    }
  }, {
    path: 'closingRecord',
    name: 'FinanceClosingRecord',
    meta: {
      title: '结算记录 ',
      noCache: true
    },
    component: function component() {
      return import('@/views/finance/closingRecord/index');
    }
  }]
};
export default financeRouter;