import request from '@/utils/request';

/**
 * 新增分类
 * @param pram
 */
export function addCategroy(pram) {
  var data = {
    extra: pram.extra,
    name: pram.name,
    pid: pram.pid,
    sort: pram.sort,
    status: pram.status,
    type: pram.type,
    url: pram.url,
    owner: pram.owner
  };
  return request({
    url: '/admin/merchant/category/save',
    method: 'POST',
    data: data
  });
}

/**
 * 分类详情
 * @param pram
 */
export function infoCategroy(pram) {
  return request({
    url: "/admin/merchant/category/info/".concat(pram.id),
    method: 'GET'
  });
}

/**
 * 删除分类
 * @param pram
 */
export function deleteCategroy(pram) {
  return request({
    url: "/admin/merchant/category/delete/".concat(pram.id),
    method: 'post'
  });
}

/**
 * 分类列表
 * @param pram
 */
export function listCategroy(pram) {
  var data = {
    limit: pram.limit,
    name: pram.name,
    page: pram.page,
    pid: pram.pid,
    status: pram.status,
    type: pram.type
  };
  return request({
    url: '/admin/category/list',
    method: 'GET',
    params: data
  });
}

/**
 * 分类数据tree数据
 * @param pram
 */
export function treeCategroy(pram) {
  var data = {
    type: pram.type,
    status: pram.status,
    name: pram.name,
    owner: pram.owner
  };
  return request({
    url: '/admin/merchant/category/list/tree',
    method: 'GET',
    params: data
  });
}

/**
 * 更新分类
 * @param pram
 */
export function updateCategroy(pram) {
  var data = {
    extra: pram.extra,
    name: pram.name,
    pid: pram.pid,
    sort: pram.sort,
    status: pram.status,
    type: pram.type,
    url: pram.url,
    id: pram.id,
    owner: pram.owner
  };
  return request({
    url: "/admin/merchant/category/update/".concat(pram.id),
    method: 'POST',
    params: data
  });
}

/**
 * 根据id集合查询对应分类列表
 * @param pram
 */
export function categroyByIds(pram) {
  var data = {
    ids: pram.ids
  };
  return request({
    url: '/admin/merchant/category/list/ids',
    method: 'GET',
    params: data
  });
}

/**
 * 修改 显示关闭状态
 * @param pram
 */
export function categroyUpdateStatus(id) {
  return request({
    url: "/admin/merchant/category/updateStatus/".concat(id),
    method: 'GET'
  });
}

/**
 * 文章详情
 * @param pram
 */
export function articleInfoApi(params) {
  return request({
    url: "/admin/article/info",
    method: 'GET',
    params: params
  });
}