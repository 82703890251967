import Layout from '@/layout';
var operationRouter = {
  path: '/operation',
  component: Layout,
  redirect: '/operation/setting',
  name: 'Operation',
  meta: {
    title: '设置',
    icon: 'clipboard',
    roles: ['admin']
  },
  children: [{
    path: 'modifyStoreInfo',
    name: 'modifyStoreInfo',
    component: function component() {
      return import('@/views/systemSetting/modifyStoreInfo');
    },
    meta: {
      title: '商户基本设置',
      icon: 'clipboard'
    }
  }, {
    path: 'roleManager',
    name: 'RoleManager',
    component: function component() {
      return import('@/views/systemSetting/administratorAuthority');
    },
    meta: {
      title: '管理权限',
      icon: 'clipboard',
      roles: ['admin']
    },
    children: [{
      path: 'identityManager',
      component: function component() {
        return import('@/views/systemSetting/administratorAuthority/identityManager');
      },
      name: 'identityManager',
      meta: {
        title: '角色管理',
        icon: ''
      }
    }, {
      path: 'adminList',
      component: function component() {
        return import('@/views/systemSetting/administratorAuthority/adminList');
      },
      name: 'adminList',
      meta: {
        title: '管理员列表',
        icon: ''
      }
    }]
  }, {
    path: 'export',
    name: 'systemExport',
    component: function component() {
      return import('@/views/systemSetting/systemExport');
    },
    meta: {
      title: '数据导出',
      icon: 'clipboard'
    },
    hidden: false
  }, {
    path: 'freightSet',
    component: function component() {
      return import('@/views/systemSetting/deliverGoods/freightSet');
    },
    name: 'freightSet',
    meta: {
      title: '运费模板',
      noCache: true
    }
  }]
};
export default operationRouter; //collate