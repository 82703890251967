import Layout from '@/layout';
var orderRouter = {
  path: '/supplyChain',
  component: Layout,
  redirect: '/supplyChain/index',
  name: 'Scm',
  alwaysShow: true,
  meta: {
    title: '供应链商品',
    icon: 'clipboard'
  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/supplyChain/index');
    },
    name: 'index',
    meta: {
      title: '供应链商品列表'
    }
  }]
};
export default orderRouter;