var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "arrbox selWidth" },
    [
      _vm._l(_vm.labelarr, function (item, index) {
        return _c(
          "el-tag",
          {
            key: index,
            staticClass: "mr5",
            attrs: {
              type: "success",
              closable: !_vm.isDisabled,
              "disable-transitions": false,
            },
            on: {
              close: function ($event) {
                return _vm.labelhandleClose(item)
              },
            },
          },
          [_vm._v(_vm._s(item) + "\n  ")]
        )
      }),
      _vm._v(" "),
      _c("el-input", {
        staticClass: "arrbox_ip",
        staticStyle: { width: "20%" },
        attrs: {
          size: "small",
          placeholder: "输入后回车",
          maxlength: "10",
          disabled: _vm.isDisabled,
        },
        on: { change: _vm.addlabel },
        model: {
          value: _vm.val,
          callback: function ($$v) {
            _vm.val = $$v
          },
          expression: "val",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }