/** 当路由表太长时，可以将其拆分为小模块 **/
import Layout from '@/layout';
var merchantRouter = {
  path: '/merchant',
  component: Layout,
  redirect: '/merchant/supplier',
  name: 'Merchant',
  meta: {
    title: '供应商',
    icon: 'clipboard'
  },
  children: [{
    path: 'supplier',
    component: function component() {
      return import('@/views/merchant/supplier/index');
    },
    name: 'MerchantSupplier',
    meta: {
      title: '供应商',
      icon: ''
    }
  }]
};
export default merchantRouter;