//
//
//
//
//

// import { copyrightInfoApi } from '@/api/authInformation';
export default {
  name: 'i-copyright',
  data: function data() {
    return {
      // copyright: 'Copyright © 2023 川供消费帮扶网',
      copyrightNew: ''
    };
  },
  mounted: function mounted() {
    this.copyrightNew = "Copyright \xA9 2023 ".concat(process.env.VUE_APP_title);
    // this.getVersion();
  },
  methods: {
    // getVersion() {
    // 	copyrightInfoApi().then((res) => {
    // 		const data = res || {};
    // 		this.copyrightNew = data ? data : this.copyright;
    // 	});
    // },
  }
};