/** When your routing table is too long, you can split it into small modules **/

import Layout from '@/layout';
var videoChannelRouter = {
  path: '/videoChannel',
  component: Layout,
  redirect: '/draftList/list',
  name: 'VideoChannel',
  meta: {
    title: '视频号',
    icon: 'clipboard'
  },
  children: [{
    path: 'productList',
    component: function component() {
      return import('@/views/videoChannel/videoList/index');
    },
    name: 'VideoChannelproductList',
    meta: {
      title: '过审商品',
      icon: '',
      noCache: true
    }
  }, {
    path: 'creatVideoChannel/:id?/:isDisabled?',
    component: function component() {
      return import('@/views/videoChannel/draftList/creatVideoChannel');
    },
    name: 'CreatVideoChannel',
    meta: {
      title: '添加视频号商品',
      icon: '',
      noCache: true,
      activeMenu: "/videoChannel/draftList"
    }
  }, {
    path: 'draftList',
    component: function component() {
      return import('@/views/videoChannel/draftList/index');
    },
    name: 'draftList',
    meta: {
      title: '草稿列表',
      icon: '',
      noCache: true
    }
  }, {
    path: 'apply',
    component: function component() {
      return import('@/views/videoChannel/apply/index');
    },
    name: 'videoApply',
    meta: {
      title: '申请接入',
      icon: '',
      noCache: true
    }
  }]
};
export default videoChannelRouter;