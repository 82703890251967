/** 当路由表太长时，可以将其拆分为小模块 **/

import Layout from '@/layout';
var marketingRouter = {
  path: '/marketing',
  component: Layout,
  redirect: '/coupon/list',
  name: 'Marketing',
  meta: {
    title: '营销',
    icon: 'clipboard'
  },
  children: [{
    path: 'seckill',
    component: function component() {
      return import('@/views/marketing/seckill/index');
    },
    name: 'Seckill',
    meta: {
      title: '秒杀',
      icon: ''
    },
    children: [{
      path: 'list',
      component: function component() {
        return import('@/views/marketing/seckill/seckillList/index');
      },
      name: 'SeckillList',
      meta: {
        title: '秒杀商品',
        icon: ''
      }
    }, {
      path: 'seckillActivity',
      component: function component() {
        return import('@/views/marketing/seckill/seckillActivity/index');
      },
      name: 'SeckillActivity',
      meta: {
        title: '秒杀活动',
        icon: ''
      }
    }, {
      path: 'creatActivity/:activityId?',
      component: function component() {
        return import('@/views/marketing/seckill/seckillActivity/creatSeckill');
      },
      name: 'creatActivity',
      meta: {
        title: '参加秒杀活动',
        icon: '',
        noCache: true,
        activeMenu: "/marketing/seckill/seckillActivity"
      }
    }, {
      path: 'creatSeckill/:activityId?',
      component: function component() {
        return import('@/views/marketing/seckill/seckillActivity/creatSeckill');
      },
      name: 'CreatSeckill',
      meta: {
        title: '添加秒杀商品',
        icon: '',
        noCache: true,
        activeMenu: "/marketing/seckill/list"
      }
    }]
  }, {
    path: 'broadcast',
    name: 'Broadcast',
    meta: {
      title: '直播',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return import('@/views/marketing/broadcast/index');
    },
    children: [{
      path: 'product',
      name: 'BroadcastProduct',
      meta: {
        title: '直播商品列表',
        noCache: true
      },
      component: function component() {
        return import('@/views/marketing/broadcast/product/index');
      }
    }, {
      path: 'creatPro/:liveId?',
      component: function component() {
        return import('@/views/marketing/broadcast/product/creatPro');
      },
      name: 'CreatPro',
      meta: {
        title: '添加直播商品',
        icon: '',
        noCache: true,
        activeMenu: "/marketing/broadcast/product"
      }
    }, {
      path: 'assistant',
      name: 'BroadcastAssistant',
      meta: {
        title: '直播助手',
        noCache: true
      },
      component: function component() {
        return import('@/views/marketing/broadcast/assistant/index');
      }
    }, {
      path: 'room',
      name: 'BroadcastRoom',
      meta: {
        title: '直播间管理',
        noCache: true
      },
      component: function component() {
        return import('@/views/marketing/broadcast/room/index');
      }
    }, {
      path: 'creatRoom/:roomId?/:type?',
      component: function component() {
        return import('@/views/marketing/broadcast/room/creatRoom');
      },
      name: 'CreatRoom',
      meta: {
        title: '添加直播间',
        icon: '',
        noCache: true,
        activeMenu: "/marketing/broadcast/room"
      }
    }]
  }]
};
export default marketingRouter;