//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * 注意：和Parser唯一的区别就是这里仅仅传入表单配置id即可自动加载已配置的表单
 *      数据后渲染表单，
 *      其他业务和Parser保持一致
 */
import * as systemFormConfigApi from '@/api/systemFormConfig.js';
import parser from '@/components/FormGenerator/components/parser/Parser';
import { Debounce } from '@/utils/validate';
export default {
  name: 'ZBParser',
  components: {
    parser: parser
  },
  props: {
    formName: {
      type: String,
      required: ''
    },
    isCreate: {
      type: Number,
      default: 0 // 0=create 1=edit
    },
    editData: {
      type: Object
    },
    keyNum: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      loading: false,
      formConf: {
        fields: []
      }
    };
  },
  watch: {
    keyNum: {
      deep: true,
      handler: function handler(val) {
        this.formConf = {
          fields: []
        };
        this.handlerGetFormConfig(this.formName);
      }
    }
  },
  mounted: function mounted() {
    this.handlerGetFormConfig(this.formName);
  },
  methods: {
    handlerGetFormConfig: function handlerGetFormConfig(formName) {
      var _this = this;
      // 获取表单配置后生成table列
      this.loading = true;
      var _pram = {
        name: encodeURIComponent(formName)
      };
      systemFormConfigApi.formTempNameInfoApi(_pram).then(function (data) {
        _this.formConf = JSON.parse(data.content);
        _this.loading = false;
      }).catch(function () {
        _this.loading = false;
      });
    },
    handlerSubmit: Debounce(function (formValue) {
      this.$emit('submit', formValue);
    }),
    resetForm: function resetForm(formValue) {
      this.$emit('resetForm', formValue);
    }
  }
};