//财务过滤器
/**
 * 资金流水 交易类型
 */
export function transactionTypeFilter(status) {
  var statusMap = {
    pay_order: '订单支付',
    refund_order: '订单退款'
  };
  return statusMap[status];
}

/**
 * 转账类型
 */
export function transferTypeFilter(status) {
  var statusMap = {
    bank: '银行卡',
    alipay: '支付宝',
    wechat: '微信'
  };
  return statusMap[status];
}