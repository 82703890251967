import Layout from '@/layout';
var productRouter = {
  path: '/product',
  component: Layout,
  redirect: '/product/list',
  name: 'Product',
  meta: {
    title: '商品',
    icon: 'clipboard'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/product/index');
    },
    name: 'ProductList',
    meta: {
      title: '商品列表',
      icon: ''
    }
  }, {
    path: 'classify',
    component: function component() {
      return import('@/views/product/category/index');
    },
    name: 'Classify',
    meta: {
      title: '商品分类',
      icon: ''
    }
  }, {
    path: 'attr',
    component: function component() {
      return import('@/views/product/attr/index');
    },
    name: 'Attr',
    meta: {
      title: '商品规格',
      icon: ''
    }
  }, {
    path: 'comment',
    component: function component() {
      return import('@/views/product/comment/index');
    },
    name: 'Comment',
    meta: {
      title: '商品评论',
      icon: ''
    }
  }, {
    //id:商品id，isDisabled：是否能编辑(1不能，2能)，isCopy：是否是采集商品(1是，2不是)
    path: 'list/creatProduct/:id?/:isDisabled/:isCopy?',
    component: function component() {
      return import('@/views/product/creatProduct/index');
    },
    name: 'creatProduct',
    meta: {
      title: '商品添加',
      noCache: true,
      activeMenu: "/product/list"
    },
    hidden: true
  }, {
    path: 'guarantee/group',
    component: function component() {
      return import('@/views/product/guarantee/index');
    },
    name: 'guarantee',
    meta: {
      title: '保障服务组合',
      icon: ''
    }
  }, {
    path: 'productLabel',
    component: function component() {
      return import('@/views/product/productLabel/index');
    },
    name: 'productLabel',
    meta: {
      title: '商品标签',
      icon: ''
    }
  }, {
    path: 'brandManagement',
    component: function component() {
      return import('@/views/product/brandManagement/index');
    },
    name: 'brandManagement',
    meta: {
      title: '品牌管理',
      icon: ''
    }
  }]
};
export default productRouter;