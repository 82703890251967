import Layout from '@/layout';
var orderRouter = {
  path: '/order',
  component: Layout,
  redirect: '/order/index',
  name: 'Order',
  alwaysShow: true,
  meta: {
    title: '订单',
    icon: 'clipboard'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/order/index');
    },
    name: 'OrderIndex',
    meta: {
      title: '订单'
    }
  }, {
    path: 'refund',
    component: function component() {
      return import('@/views/order/refund/index');
    },
    name: 'refund',
    meta: {
      title: '退款订单'
    }
  }]
};
export default orderRouter;