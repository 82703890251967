import Layout from '@/layout';
var couponRouter = {
  path: '/coupon',
  component: Layout,
  redirect: '/coupon/list',
  name: 'Coupon',
  meta: {
    title: '优惠券',
    icon: 'clipboard'
  },
  children: [{
    path: 'list/save',
    name: 'couponAdd',
    meta: {
      title: '优惠劵添加',
      noCache: true,
      activeMenu: "/coupon/list"
    },
    hidden: true,
    component: function component() {
      return import('@/views/coupon/list/creatCoupon');
    }
  }, {
    path: 'list/save/:id?/:edit?',
    name: 'couponEdit',
    meta: {
      title: '优惠劵编辑',
      noCache: true,
      activeMenu: "/coupon/list"
    },
    hidden: true,
    component: function component() {
      return import('@/views/coupon/list/creatCoupon');
    }
  }, {
    path: 'list',
    component: function component() {
      return import('@/views/coupon/list/index');
    },
    name: 'List',
    meta: {
      title: '优惠券列表',
      icon: ''
    }
  }, {
    path: 'record',
    component: function component() {
      return import('@/views/coupon/record/index');
    },
    name: 'Record',
    meta: {
      title: '领取记录',
      icon: ''
    }
  }]
};
export default couponRouter;