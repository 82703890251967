import request from '@/utils/request';
export function getFormConfigInfo(pram) {
  var data = {
    id: pram.id
  };
  return request({
    url: '/admin/merchant/form/temp/info',
    method: 'GET',
    params: data
  });
}

/**
 * 通过名称查询详情
 * @param pram
 */
export function formTempNameInfoApi(params) {
  return request({
    url: "/admin/merchant/form/temp/name/info",
    method: 'get',
    params: params
  });
}