import request from '@/utils/request';

/**
 * 新增商品
 * @param pram
 */
export function productCreateApi(data) {
  return request({
    url: '/admin/merchant/product/save',
    method: 'POST',
    data: data
  });
}

/**
 * 商品批量提审
 * @param pram
 */
export function ubmitBatchAudit(data) {
  return request({
    url: '/admin/merchant/product/submit/batch/audit',
    method: 'POST',
    data: data
  });
}

/**
 * 编辑商品
 * @param pram
 */
export function productUpdateApi(data) {
  return request({
    url: '/admin/merchant/product/update',
    method: 'POST',
    data: data
  });
}

/**
 * 商品详情
 * @param pram
 */
export function productDetailApi(id) {
  return request({
    url: "/admin/merchant/product/info/".concat(id),
    method: 'GET'
  });
}

/**
 * 删除商品
 * @param pram
 */
export function productDeleteApi(data) {
  return request({
    url: "/admin/merchant/product/delete",
    method: 'post',
    data: data
  });
}

/**
 * 商品列表 表头数量
 */
export function productHeadersApi() {
  return request({
    url: '/admin/merchant/product/tabs/headers',
    method: 'GET'
  });
}

/**
 * 商品列表
 * @param pram
 */
export function productLstApi(params) {
  return request({
    url: '/admin/merchant/product/list',
    method: 'GET',
    params: params
  });
}
/**
 * 平台端商品分类缓存树
 * @param pram
 */
export function categoryApi() {
  return request({
    url: '/admin/merchant/plat/product/category/cache/tree',
    method: 'GET'
  });
}
/**
 * 商户端商品分类缓存树
 * @param pram
 */
export function storeCategoryAllApi() {
  return request({
    url: '/admin/merchant/store/product/category/cache/tree',
    method: 'GET'
  });
}

/**
 * 商户端商品分类缓存树
 * @param pram
 */
export function productCategoryList() {
  return request({
    url: '/admin/merchant/store/product/category/cache/tree',
    method: 'GET'
  });
}

/**
 * 商品上架
 * @param pram
 */
export function putOnShellApi(id) {
  return request({
    url: "/admin/merchant/product/up/".concat(id),
    method: 'post'
  });
}
/**
 * 商品下架
 * @param pram
 */
export function offShellApi(id) {
  return request({
    url: "/admin/merchant/product/down/".concat(id),
    method: 'post'
  });
}
/**
 * 商品规格 列表
 * @param pram
 */
export function templateListApi(params) {
  return request({
    url: '/admin/merchant/product/rule/list',
    method: 'GET',
    params: params
  });
}
/**
 * 商品规格 删除
 * @param pram
 */
export function attrDeleteApi(id) {
  return request({
    url: "/admin/merchant/product/rule/delete/".concat(id),
    method: 'post'
  });
}
/**
 * 商品规格 新增
 * @param pram
 */
export function attrCreatApi(data) {
  return request({
    url: '/admin/merchant/product/rule/save',
    method: 'POST',
    data: data
  });
}
/**
 * 商品规格 编辑
 * @param pram
 */
export function attrEditApi(data) {
  return request({
    url: '/admin/merchant/product/rule/update',
    method: 'POST',
    data: data
  });
}
/**
 * 商品规格 详情
 * @param pram
 */
export function attrInfoApi(id) {
  return request({
    url: "admin/merchant/product/rule/info/".concat(id),
    method: 'GET'
  });
}
/**
 * 商品评论 列表
 * @param pram
 */
export function replyListApi(params) {
  return request({
    url: '/admin/merchant/product/reply/list',
    method: 'GET',
    params: params
  });
}
/**
 * 商品评论 新增
 * @param pram
 */
export function replyCreatApi(data) {
  return request({
    url: '/admin/merchant/product/reply/virtual',
    method: 'POST',
    data: data
  });
}
/**
 * 商品评论 编辑
 * @param pram
 */
export function replyEditApi(data) {
  return request({
    url: '/admin/store/product/reply/update',
    method: 'POST',
    data: data
  });
}
/**
 * 商品评论 详情
 * @param pram
 */
export function replyInfoApi(id) {
  return request({
    url: "/admin/store/product/reply/info/".concat(id),
    method: 'GET'
  });
}
/**
 * 商品评论 删除
 * @param pram
 */
export function replyDeleteApi(id) {
  return request({
    url: "/admin/merchant/product/reply/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 商品评论 回复
 * @param pram
 */
export function replyCommentApi(data) {
  return request({
    url: "/admin/merchant/product/reply/comment",
    method: 'post',
    data: data
  });
}

/**
 * 商品评论 导出
 * @param pram
 */
export function productExportApi(params) {
  return request({
    url: "/admin/export/excel/product",
    method: 'get',
    params: params
  });
}

/**
 * 商品复制 99Api
 * @param pram
 */
export function importProductApi(params) {
  return request({
    url: "/admin/merchant/product/importProduct",
    method: 'post',
    params: params
  });
}

/**
 * 商品复制 一号通
 * @param pram
 */
export function copyProductApi(data) {
  return request({
    url: "/admin/merchant/product/copy/product",
    method: 'post',
    data: data
  });
}

/**
 * 恢复
 * @param pram
 */
export function restoreApi(id) {
  return request({
    url: "/admin/merchant/product/restore/".concat(id),
    method: 'post'
  });
}

/**
 * 商品列表 导出
 * @param pram
 */
export function productExcelApi(params) {
  return request({
    url: "/admin/export/excel/product",
    method: 'get',
    params: params
  });
}

/**
 * 商品列表 获取复制商品配置
 * @param pram
 */
export function copyConfigApi() {
  return request({
    url: "/admin/merchant/product/copy/config",
    method: 'post'
  });
}

/**
 * 订单数据 导出
 * @param pram
 */
export function orderExcelApi(params) {
  return request({
    url: "/admin/export/excel/order",
    method: 'get',
    params: params
  });
}

/**
 * 商品分类 列表
 * @param pram
 */
export function productCategoryListApi(params) {
  return request({
    url: '/admin/merchant/store/product/category/list',
    method: 'GET',
    params: params
  });
}
/**
 * 商品分类 新增
 * @param pram
 */
export function productCategoryAddApi(data) {
  return request({
    url: '/admin/merchant/store/product/category/add',
    method: 'post',
    data: data
  });
}
/**
 * 商品分类 编辑
 * @param pram
 */
export function productCategoryUpdateApi(data) {
  return request({
    url: '/admin/merchant/store/product/category/update',
    method: 'post',
    data: data
  });
}
/**
 * 商品分类 删除
 * @param pram
 */
export function productCategoryDeleteApi(id) {
  return request({
    url: "/admin/merchant/store/product/category/delete/".concat(id),
    method: 'post'
  });
}
/**
 * 商品分类 修改分类显示状态
 * @param pram
 */
export function productCategoryShowApi(id) {
  return request({
    url: "/admin/merchant/store/product/category/update/show/".concat(id),
    method: 'post'
  });
}
/**
 * 保障服务列表
 * @param pram
 */
export function productGuaranteeApi() {
  return request({
    url: "/admin/merchant/plat/product/guarantee/list",
    method: 'get'
  });
}
/**
 * 品牌列表 全部
 * @param pram
 */
export function brandAllApi() {
  return request({
    url: "/admin/merchant/plat/product/brand/cache/list",
    method: 'get'
  });
}

/**
 * 品牌列表
 * @param pram
 */
export function brandListApi(params) {
  return request({
    url: "/admin/merchant/plat/product/brand/list",
    method: 'get',
    params: params
  });
}

/**
 * 优惠券 列表
 * @param pram
 */
export function marketingListApi(params) {
  return request({
    url: '/admin/merchant/coupon/list',
    method: 'get',
    params: params
  });
}

/**
 * 商品可用优惠券列表
 * @param pram
 */
export function productCouponListApi() {
  return request({
    url: '/admin/merchant/coupon/product/usable/list',
    method: 'get'
  });
}

/**
 * 优惠券 详情
 * @param pram
 */
export function couponInfoApi(id) {
  return request({
    url: "/admin/merchant/coupon/info/".concat(id),
    method: 'get'
  });
}

/**
 * 优惠券 修改状态
 * @param pram
 */
export function couponIssueStatusApi(id) {
  return request({
    url: "/admin/merchant/coupon/update/status/".concat(id),
    method: 'post'
  });
}

/**
 * 优惠券 删除
 * @param pram
 */
export function couponDeleteApi(id) {
  return request({
    url: "/admin/merchant/coupon/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 优惠券 新增
 * @param pram
 */
export function couponSaveApi(data) {
  return request({
    url: "/admin/merchant/coupon/save",
    method: 'post',
    data: data
  });
}

/**
 * 优惠券 商品券关联商品编辑
 * @param pram
 */
export function couponProductEditApi(data) {
  return request({
    url: "/admin/merchant/coupon/product/join/edit",
    method: 'post',
    data: data
  });
}

/**
 * 保障服务组合 列表
 * @param pram
 */
export function guaranteeListApi(params) {
  return request({
    url: "/admin/merchant/product/guarantee/group/list",
    method: 'get',
    params: params
  });
}

/**
 * 保障服务组合 新增
 * @param pram
 */
export function guaranteeAddApi(data) {
  return request({
    url: "/admin/merchant/product/guarantee/group/add",
    method: 'post',
    data: data
  });
}

/**
 * 保障服务组合 编辑
 * @param pram
 */
export function guaranteeUpdateApi(data) {
  return request({
    url: "/admin/merchant/product/guarantee/group/edit",
    method: 'post',
    data: data
  });
}

/**
 * 保障服务组合 删除
 * @param pram
 */
export function guaranteeDeleteApi(id) {
  return request({
    url: "/admin/merchant/product/guarantee/group/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 商品提审
 * @param pram
 */
export function productAuditApi(id) {
  return request({
    url: "/admin/merchant/product/submit/audit/".concat(id),
    method: 'post'
  });
}

/**
 * 快捷添加库存
 * @param pram
 */
export function productStockAddApi(data) {
  return request({
    url: "/admin/merchant/product/quick/stock/add",
    method: 'post',
    data: data
  });
}

/**
 * 商品免审编辑
 * @param pram
 */
export function productFreeEditApi(data) {
  return request({
    url: "/admin/merchant/product/review/free/edit",
    method: 'post',
    data: data
  });
}

/**
 * 商品搜索分页列表（活动）
 * @param pram
 */
export function productActivityListApi(params) {
  return request({
    url: '/admin/merchant/product/activity/search/page',
    method: 'get',
    params: params
  });
}

/**
 * 商户商品标签
 * @param pram
 */
export function productLabelPage(data) {
  return request({
    url: "/admin/platform/product/label/page",
    method: 'post',
    data: data
  });
}

/**
 *  商户商品新增
 *  @param pram
*/
export function productLabelSave(data) {
  return request({
    url: "/admin/platform/product/label/save",
    method: 'post',
    data: data
  });
}

/**
 * 删除商品商户
 * @param pram
 */
export function productLabelDelete(id) {
  return request({
    url: "/admin/platform/product/label/delete/".concat(id),
    method: 'DELETE'
  });
}

/**
 *  商户商品修改
 *  @param pram
*/
export function productLabelUpdate(data) {
  return request({
    url: "/admin/platform/product/label/update",
    method: 'put',
    data: data
  });
}

/*
* 商户商品查询列表
*/

export function productLabelList(data) {
  return request({
    url: "/admin/platform/product/label/list",
    method: 'POST',
    data: data
  });
}

/*
* 平台资质查询列表
*/

export function productCertLabelList(data) {
  return request({
    url: "/admin/platform/product/cert/label/list",
    method: 'POST',
    data: data
  });
}

/*
* 产地地址列表
*/
export function systemCertLabelList(data) {
  return request({
    url: "/admin/platform/system/city/area/all",
    method: 'POST',
    data: data
  });
}

/*
* 区域品牌列表
*/
export function productAreaBrandList(data) {
  return request({
    url: "/admin/platform/product/area/brand/list",
    method: 'POST',
    data: data
  });
}

/*
* 供应商列表
*/
export function merchantSupplierList(params) {
  return request({
    url: "/admin/merchant/supplier/list",
    method: 'GET',
    params: params
  });
}

/**
 * 
*/
export function productQuickStockAdd(data) {
  return request({
    url: "/admin/merchant/product/quick/stock/add",
    method: 'POST',
    data: data
  });
}

/**
 *  品牌管理 --- 列表
*/
export function productBrandPpplyList(data) {
  return request({
    url: "/admin/merchant/product/brand/apply/list",
    method: 'POST',
    data: data
  });
}

/**
 *  品牌管理 --- 查看资料
*/
export function productBrandPpplyDoc(id) {
  return request({
    url: "/admin/merchant/product/brand/apply/doc/".concat(id),
    method: 'get'
  });
}

/**
 *  品牌管理 --- 申请品牌授权->选择列表
*/
export function productBrandPpplySelectList(data) {
  return request({
    url: "/admin/merchant/product/brand/apply/select/list",
    method: 'post',
    data: data
  });
}

/**
 *  品牌管理 --- 所属类目(筛选条件)
*/
export function productBrandPpplyCategoryTop(data) {
  return request({
    url: "/admin/merchant/product/brand/category/top",
    method: 'get',
    data: data
  });
}

/**
 *  品牌管理 --- 所属类目(筛选条件)
*/
export function productBrandPpplyCateTree(params) {
  return request({
    url: "/admin/merchant/product/brand/cate/tree",
    method: 'get',
    params: params
  });
}

/**
 *  品牌管理 --- 申请品牌授权
*/
export function productBrandPpplyapplyAdd(data) {
  return request({
    url: "/admin/merchant/product/brand/apply/add",
    method: 'POST',
    data: data
  });
}

/**
 *  品牌管理 --- 创建新品牌申请(新增)
*/
export function productBrandAdd(data) {
  return request({
    url: "/admin/merchant/product/brand/add",
    method: 'POST',
    data: data
  });
}

/**
 *  品牌管理 --- 已授权品牌列表
*/
export function productBrandAuthorizedList(data) {
  return request({
    url: "/admin/merchant/product/brand/authorized/list",
    method: 'POST',
    data: data
  });
}

/**
 *  新增商品-是否可选择配送方式
*/
export function addProductScm(data) {
  return request({
    url: "/admin/merchant/scm/info",
    method: 'GET'
  });
}

/**
 *  品牌管理 --- 品牌授权申请记录
*/
export function productBrandRecordList(data) {
  return request({
    url: "/admin/merchant/product/brand/record/list",
    method: 'POST',
    data: data
  });
}

/**
 *  商品下架 --- 批量下架
*/
export function productBatchDown(data) {
  return request({
    url: "/admin/merchant/product/batch/down",
    method: 'POST',
    data: data
  });
}

/**
 *  商品上架 --- 批量上架
*/
export function productBatchUp(data) {
  return request({
    url: "/admin/merchant/product/batch/up",
    method: 'POST',
    data: data
  });
}